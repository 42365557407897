import { Stack } from '@fluentui/react'
import { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'
import { CompliantExternalLink, landingPages } from 'src/components'

export const ForbiddenErrorMessage: FunctionComponent<any> = () => {
  const location = useLocation()
  const { securityGroup } = landingPages.getPage(location.pathname)

  return (
    <>
      {securityGroup ? (
        <Stack>
          {'Join to the security group: '}
          <CompliantExternalLink href={securityGroup.requestAccessLink} target="_blank">
            {securityGroup.groupName}
          </CompliantExternalLink>
        </Stack>
      ) : (
        <Stack>
          {'You should have correct access rights or be a PM owner/Dev owner/Admin for a service in Service Tree'}
        </Stack>
      )}
    </>
  )
}
