import { SgQueryKey } from './Shared'
import { apiService } from 'src/services/graphapi'
import { useInfiniteQuery } from 'react-query'
const PAGE_SIZE = 500

const getRTI = async (team?: string, environment?: string, pageNumber?: number, pageSize?: number) => {
  const response = await apiService.rTI_Get(team, environment, pageNumber, pageSize)
  return response
}

export function useRTI(team?: string, environment?: string) {
  return useInfiniteQuery(
    [SgQueryKey.rti, team, environment],
    ({ pageParam }) => getRTI(team, environment, pageParam, PAGE_SIZE),
    {
      getNextPageParam: (page, _) => {
        return page?.pagination?.hasNextPage ? page?.pagination?.nextPage : undefined
      },
      refetchOnWindowFocus: false,
    },
  )
}
