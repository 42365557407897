import { Label, ILabelStyles } from '@fluentui/react'
import { HelpfulLabel } from 'src/components/HelpfulLabel'
import { requiredAutoCompleteLabelStyles } from '../../shared'

export const useOnRenderLabel = (hint?: string, label?: string, required?: boolean, styles?: Partial<ILabelStyles>) => {
  const onRenderLabel = () => {
    if (label) {
      return hint ? (
        <HelpfulLabel label={label} hint={hint} required={required} styles={styles} />
      ) : (
        <Label styles={required ? { ...styles, ...requiredAutoCompleteLabelStyles } : styles}>{label}</Label>
      )
    }
    return null
  }

  return onRenderLabel
}
