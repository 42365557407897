import { lazy } from 'react'
import { ILandingPage, ISecurityGroup } from '../Models'
import { CLOUD_PARITY_REPORT, CONFIDENTIAL_FLAG } from 'src/constants'

const CloudParityReportPage = lazy(() => import('../../../pages/CloudParityReport/CloudParityReport'))
const SECURITY_GROUP_NAME = 'Experience Symmetry Cloud Parity Report'

export const CloudParityReportRoute = {
  navigationTitle: CLOUD_PARITY_REPORT,
  icon: 'ShowResultsMirrored',
  description: [`(${CONFIDENTIAL_FLAG})`],
  externalLink: 'https://eng.ms/docs/experiences-devices/ed-inventory-management/experiencesymmetry/cloudparity',
  externalLinkText: 'Report Manual',
  securityGroup: {
    groupName: SECURITY_GROUP_NAME,
    requestAccessLink: `https://idwebelements.microsoft.com/GroupManagement.aspx?Group=${SECURITY_GROUP_NAME}&Operation=join`,
  } as ISecurityGroup,
  element: <CloudParityReportPage />,
} as ILandingPage
