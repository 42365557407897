import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServiceComponents = async (skuIds?: string, productIds?: string) => {
  const response = await apiService.serviceComponents_GetServiceComponents(skuIds, productIds)
  return response
}

export function useServiceComponents(skuIds?: string, productIds?: string, enabled = true) {
  return useQuery([SgQueryKey.serviceComponents, skuIds, productIds], () => getServiceComponents(skuIds, productIds), {
    staleTime: 5 * MINUTE,
    enabled,
  })
}
