import { ILabelStyles, Label, mergeStyleSets, TooltipHost } from '@fluentui/react'
import { requiredAutoCompleteLabelStyles } from '../shared'
import { InfoIcon } from '../Icons'

const getClassNames = () => {
  return mergeStyleSets({
    displayInline: {
      display: 'inline-block',
    },
    iconContainer: {
      marginLeft: '0.2rem',
      verticalAlign: 'middle',
      cursor: 'default',
    },
    iconContainerRequired: {
      marginLeft: '0.4rem',
      verticalAlign: 'middle',
      cursor: 'default',
    },
  })
}

export interface HelpfulLabelProps {
  label: string
  hint: string
  required?: boolean
  styles?: Partial<ILabelStyles>
}

export const HelpfulLabel: React.FunctionComponent<HelpfulLabelProps> = ({ label, hint, styles, required }) => {
  const classes = getClassNames()

  return (
    <>
      <Label
        className={classes.displayInline}
        styles={required ? { ...styles, ...requiredAutoCompleteLabelStyles } : styles}
      >
        {label}
      </Label>
      <TooltipHost content={hint}>
        <div className={`${classes.displayInline} ${required ? classes.iconContainerRequired : classes.iconContainer}`}>
          <InfoIcon />
        </div>
      </TooltipHost>
    </>
  )
}
