import { IComboBoxOption } from '@fluentui/react'
import { useServiceComponents } from 'src/queries'
import { ServiceComponentVM } from 'src/services/graphapi'
import { useOptions } from '../../hooks'
import { Filters } from 'src/enums'

export const useServiceComponentOptions = (skuIds?: string, productIds?: string) => {
  const queryResult = useServiceComponents(skuIds, productIds)
  const map = (item: ServiceComponentVM) => ({ key: item.id || '', text: item.name || '' } as IComboBoxOption)
  const transform = (items: ServiceComponentVM[]) => items.map((item) => map(item))
  const options = useOptions<ServiceComponentVM>({
    queryResult,
    transform,
    name: Filters.ServiceComponent,
    showTitle: true,
  })

  return options
}
