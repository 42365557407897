import { ILandingPage, ISecurityGroup } from '../Models'
import { REAL_TIME_INVENTORY } from 'src/constants'
import { lazy } from 'react'

const RtiPage = lazy(() => import('../../../pages/RealTimeInventory/RTI'))

export const Rti = {
  navigationTitle: REAL_TIME_INVENTORY,
  icon: 'DietPlanNotebook',
  securityGroup: {
    groupName: 'Service Graph M365 Asset Inventory Users',
    requestAccessLink: 'https://coreidentity.microsoft.com/manage/Entitlement/entitlement/servicegraph-aat0',
  } as ISecurityGroup,
  element: <RtiPage />,
} as ILandingPage
