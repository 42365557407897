import { useSearchParamValue } from 'src/hooks'
import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { useUrlAutoCompleteMultiSelect } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { useSkuOptions } from './useSkuOptions'
import { QueryStringParam } from 'src/enums'

export const SkuUrlAutoCompleteMultiselect = ({ filterSize }: CustomFilterSizeProps) => {
  const [skuTypes] = useSearchParamValue(QueryStringParam.SkuType)
  const options = useSkuOptions(skuTypes)
  const { urlValue, onChangeMultipleValues } = useUrlAutoCompleteMultiSelect({
    urlKey: QueryStringParam.SkuIds,
    dependents: [QueryStringParam.ProductIds, QueryStringParam.ServicePlanIds, QueryStringParam.ServiceComponentIds],
  })
  const selectedKeys = urlValue?.split('_') ?? []

  return (
    <AutoCompleteMultiselect
      {...options}
      selectedKey={selectedKeys}
      onChangeMultipleValues={onChangeMultipleValues}
      filterSize={filterSize}
      selectedOptionLimit={1}
    />
  )
}
