import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'
import { useInfiniteQuery } from 'react-query'

const PAGE_SIZE = 200

export const getReport = async (
  skuIds?: string,
  productIds?: string,
  servicePlanIds?: string,
  serviceSearchValue?: string,
  serviceComponents?: string,
  serviceTier?: string,
  skip?: number,
  limit?: number,
) => {
  const response = await apiService.reports_GetSkuMappingReport(
    skuIds,
    productIds,
    servicePlanIds,
    serviceSearchValue,
    serviceComponents,
    serviceTier,
    skip,
    limit,
  )
  return response
}

export function useSkuMappingReport(
  skuIds?: string,
  productIds?: string,
  servicePlanIds?: string,
  serviceSearchValue?: string,
  serviceComponents?: string,
  serviceTier?: string,
) {
  return useInfiniteQuery(
    [
      SgQueryKey.skuMappingReport,
      skuIds,
      productIds,
      servicePlanIds,
      serviceSearchValue,
      serviceComponents,
      serviceTier,
    ],
    ({ pageParam }) =>
      getReport(
        skuIds,
        productIds,
        servicePlanIds,
        serviceSearchValue,
        serviceComponents,
        serviceTier,
        pageParam || 0,
        PAGE_SIZE,
      ),
    {
      getNextPageParam: (lastPage, _pages) => {
        return lastPage.pagination?.nextPage
      },
      refetchOnWindowFocus: false,
    },
  )
}
