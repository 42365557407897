import { useSearchParamValue } from 'src/hooks'
import { AutoComplete } from '../../AutoComplete'
import { useUrlAutoComplete } from '../../hooks'
import { CustomFilterSizeProps, CustomAutoCompleteProps } from '../Props'
import { useSkuOptions } from './useSkuOptions'
import { QueryStringParam } from 'src/enums'

export const SkuUrlAutoComplete = ({ filterSize, required }: CustomFilterSizeProps & CustomAutoCompleteProps) => {
  const [skuTypes] = useSearchParamValue(QueryStringParam.SkuType)
  const options = useSkuOptions(skuTypes)
  const { urlValue, onChange } = useUrlAutoComplete({
    urlKey: QueryStringParam.SkuIds,
    dependents: [QueryStringParam.ProductIds, QueryStringParam.ServicePlanIds, QueryStringParam.ServiceComponentIds],
  })

  return (
    <AutoComplete {...options} selectedKey={urlValue} onChange={onChange} filterSize={filterSize} required={required} />
  )
}
