import { SgQueryKey } from './Shared'
import { apiService, CreateRTIWorldWideInventoryZipReportQuery } from 'src/services/graphapi'
import { useQuery } from 'react-query'

export function useRTIWorldWideInventoryZipReport(query?: CreateRTIWorldWideInventoryZipReportQuery) {
  return useQuery(
    [SgQueryKey.rtiWorldWideInventoryZipDownloadReport],
    () => apiService.rTI_GetRTIWorldWideInventoryZipReportDownload(query),
    { enabled: false }, // Run query on demand
  )
}
