import { IComboBoxProps } from '@fluentui/react'
import { FilterSize, getComboBoxStyles } from '../Filters'
import { tourTargetIds } from '../Tour'
import { FocusOnClickResponse } from './hooks'

export const getComboBoxProps = (focusOnClick: FocusOnClickResponse, filterSize?: FilterSize, isError?: boolean) => {
  const styles = getComboBoxStyles(filterSize)
  const { componentRef, onClick } = focusOnClick
  return {
    allowFreeform: true,
    autoComplete: 'on',
    calloutProps: { doNotLayer: false },
    componentRef,
    errorMessage: isError ? `Couldn't fetch the data` : undefined,
    id: tourTargetIds.autoCompleteSelector,
    onClick,
    openOnKeyboardFocus: false,
    scrollSelectedToTop: true,
    shouldRestoreFocus: true,
    styles,
  } as IComboBoxProps
}
