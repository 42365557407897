import { ILabelStyles } from '@fluentui/react'

export const requiredAutoCompleteLabelStyles: ILabelStyles = {
  root: {
    ':after': {
      position: 'absolute',
      content: `'*'`,
      color: 'red',
    },
  },
}
