import { AutoCompleteMultiselect } from '../../AutoCompleteMultiselect'
import { useUrlAutoCompleteMultiSelect } from '../../hooks'
import { CustomFilterSizeProps } from '../Props'
import { useServiceComponentOptions } from './useServiceComponentOptions'
import { useSearchParamValue } from 'src/hooks'
import { QueryStringParam } from 'src/enums'

export const ServiceComponentAutoCompleteMultiSelect = ({ filterSize }: CustomFilterSizeProps) => {
  const [skuIds] = useSearchParamValue(QueryStringParam.SkuIds)
  const [productIds] = useSearchParamValue(QueryStringParam.ProductIds)
  const options = useServiceComponentOptions(skuIds, productIds)
  const { urlValue, onChangeMultipleValues } = useUrlAutoCompleteMultiSelect({
    urlKey: QueryStringParam.ServiceComponentIds,
  })
  const selectedKeys = urlValue?.split('_') ?? []
  return (
    <AutoCompleteMultiselect
      {...options}
      selectedKey={selectedKeys}
      onChangeMultipleValues={onChangeMultipleValues}
      filterSize={filterSize}
      selectedOptionLimit={5}
    />
  )
}
